div.contenteditable:not(:focus) ~ div.text-editor ul.autocomplete {
  display: none;
}

ul.autocomplete {
  width: 15rem;
  max-height: 10rem;
  z-index: 2;
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  padding: 0;
  list-style-type: none;
  position: absolute;
  overflow-y: auto;
}

ul.autocomplete li.candidate {
  --candidate-color: lightgray;
  border-bottom: 1px solid #d3d3d3;
  padding: 1px;
}

ul.autocomplete li.candidate.active {
  background-color: #d3d3d3;
}

ul.autocomplete li.candidate:last-child {
  border: none;
}

ul.autocomplete li.candidate:before {
  content: "";
  background-color: var(--candidate-color);
  height: 1ex;
  width: 1ex;
  border-radius: .5ex;
  margin-left: 2px;
  margin-right: .5em;
  display: inline-block;
}

/*# sourceMappingURL=index.b7ed98b5.css.map */
